import css from './Skeleton.module.css';
import React from 'react';

const Skeleton = ({ width = '100%', height = 20, circle = false }) => {
  const skeletonStyle = {
    width,
    height,
    borderRadius: circle ? '50%' : '4px',
  };

  return <div className={css.skeleton} style={skeletonStyle}></div>;
};

export default Skeleton